.elementor-section {
	background-image: url(./images/s1/lines-bg2.png);
	background-repeat: no-repeat;
    background-position: center;
    background-size: 117%;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.elementor-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
	padding-bottom: 180px;
	box-shadow: inset 0px -6px 20px 20px #01010e;
}

.elementor-row {
    display: flex;
    flex-wrap: wrap;
}

.spline {
    position: absolute;
    margin: 0;
    top: 100px;
    right: 0;
    width: 1200px;
    height: 1000px;
}

.spline-img {
    position: absolute;
    margin-right: 250px;
	top: 150px;
	right: 0;
	width: 40%;
    height: auto;
	display: block;
    max-width: 100%;
}

@media screen and (max-width: 2000px) {
	.spline-img {
		width: 55%;
		margin-right: 20px;
	}
}

.loader {
	position: absolute;
	display: flex;
	justify-content: right;
	align-items:flex-end;
	width: 73%;
	height: 50vh;
}

a {
	text-decoration: none;
}

.donwload-Social img {
	margin-top: 10px;
	margin-right: 10px;
	width: 32px;
	height: 32px;
}

@media (max-width: 375px) {
	.donwload-h2 {
		font-size: 16px;
	}
}

@media (max-width: 1024px) {
	.spline-img {
		margin-right: 0;
		width: 75%;
	}
    .spline {
        transform: scale(0.8) translateX(200px);
        transform-origin: top;
	}
	.elementor-container {
		padding-bottom: 20px;
		box-shadow: inset 0px -6px 20px 3px #01010e;
	}
}

@media (max-width: 800px) {
	.spline-img {
		margin-right: 0;
		width: 75%;
	}
    .spline {
        transform: scale(0.7) translateX(600px);}
}

@media (max-width: 600px) {
	.spline-img {
		margin-right: 0;
		width: 100%;
	}
    .spline {
        transform: scale(0.5) translateX(-100px);
        right: auto;
        left: 50%;
        margin-left: -600px;}
	}

@media (max-width: 375px) {
	.spline-img {
		margin-right: 0;
		width: 100%;
	}
    .spline {
        transform: scale(0.45) translateX(-50px);}
}

.content {
    position: relative;
    top: -70px;
    width: 100%;
    padding: 0.5rem calc((50vw - 850px) / 2);
    padding-bottom: 150px;
    pointer-events: none;

    display: flex;
    flex-direction: column;
    gap: 40px;
}

@media (max-width: 1024px) {
    .content {
        gap: 40px;
    }
}

.content h1 {
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    font-size: 70px;
    margin: 0;
    line-height: 65px;
    max-width: 800px;
    pointer-events: auto;
	color: #fff;
    text-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1980px) {
	.elementor-section {
		background-size: 170%;
	}
}

@media (max-width: 1360px) {
	.elementor-section {
		background-size: 250%;
	}
}

@media (max-width: 1050px) {
	.elementor-section {
		background-size: 550%;
	}
}

@media (max-width: 1024px) {
    .content h1 {
        font-size: 60px;
        max-width: 400px;
    }
}
@media (max-width: 800px) {
    .content h1 {
        font-size: 50px;
        max-width: 400px;
    }
}
.content p {
	font-weight: normal;
	line-height: 150%;
	max-width: 586px;
	color: #fff;
	pointer-events: auto;
	text-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}
@media (max-width: 600px) {
    .content h1 {
        padding-top: 80px;
		font-size: 28px;
		line-height: 30px;
		text-shadow: 0px 0px 33.6672px #110239, 0px 0px 19.2384px #110239, 0px 0px 11.2224px #110239, 0px 0px 5.6112px #110239, 0px 0px 1.6032px #110239, 0px 0px 0.8016px #110239;
    }
	.content p {
		font-size: 16px;
		text-shadow: 0px 0px 33.6672px #110239, 0px 0px 19.2384px #110239, 0px 0px 11.2224px #110239, 0px 0px 5.6112px #110239, 0px 0px 1.6032px #110239, 0px 0px 0.8016px #110239;
	}
}


.content button {
    position: absolute;
	background: #6732FF;
    border: 0px;
	width: 368px;
    font-size: 16px;
    padding: 15px 30px;
	border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 12px;
    color: white;
    max-width: 280px;
    backdrop-filter: blur(20px);
	font-family: 'Nunito', sans-serif;
	font-weight: 700;
    font-weight: 600;
    box-shadow: 0px 0px 14.8176px #330AA3, 0px 0px 8.4672px #330AA3, 0px 0px 4.9392px #330AA3, 0px 0px 2.4696px #330AA3, 0px 0px 0.7056px #330AA3, 0px 0px 0.3528px #330AA3;
    transition: 1s;
    cursor: pointer;
    pointer-events: auto;

    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 25px;
}

.content button:hover {
    border: 1px solid rgba(255, 255, 255, 0.8);
    transform: translateY(-3px);
}

.content h1, .content p, .content button {
    margin: 0 30px 0 100px;
}

@media (max-width: 1024px) {
    .content h1, .content p, .content button { 
        margin: 0 30px;
    }
  }

.social {
    position: absolute;
	padding: 0.5rem calc((50vw - 900px) / 2);
    top: 190px;
    left: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

@media (max-width: 1024px) {
    .social {
		display: none;
	}
  }

.social div {
    width: 1px;
    height: 500px;
    background: linear-gradient(
      180deg,
      #08b6f9 0%,
      #6c56ef 33.57%,
      #1306dd 65.86%,
      #aa0eb2 100%
    );
}

.vertical-timeline::before {
    border-radius: 20px;
}

/* section 2 */

.hero-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 250px;
	padding-bottom: 200px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.hero-info-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.hero-subheader {
	margin-bottom: 25px;
	color: #00e0b8;
	font-size: 15px;
	font-weight: 700;
	letter-spacing: 3px;
	text-transform: uppercase;
}

.h1 {
	margin-top: 0px;
	margin-bottom: 30px;
	font-family: 'Nunito', sans-serif;
	color: #fff;
	font-size: 68px;
	line-height: 1.1;
	font-weight: 500;
}

.h1.is-404-h1 {
	margin-bottom: 15px;
}

.hero-info {
	position: relative;
	z-index: 989;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 615px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.p {
	width: 550px;
	max-width: 100%;
	margin-bottom: 40px;
}

.p.is-s2-p {
	width: 640px;
	margin-bottom: 0px;
}

.p.is-s4-p {
	margin-bottom: 0px;
}

.p.is-s3-p {
	margin-bottom: 30px;
}

.p.is-s6-p {
	margin-bottom: 30px;
}

.p.is-404-p {
	margin-bottom: 30px;
}

.filled-button {
	background: #6732FF;
    width: 174px;
    border: 0px;
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 12px;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 280px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    transition: 1s;
    cursor: pointer;
    pointer-events: auto;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
	box-shadow: 0px 0px 33.6672px #4c1bd3, 0px 0px 19.2384px #4c1bd3, 0px 0px 11.2224px #4c1bd3, 0px 0px 5.6112px #4c1bd3, 0px 0px 1.6032px #4c1bd3, 0px 0px 0.8016px #4c1bd3;
}
/* .filled-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 170px;
	height: 50px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 12px;
	background-image: linear-gradient(244deg, rgb(207, 56, 255), rgb(149, 87, 255) 50%, rgb(1, 140, 233));
	-webkit-transition: box-shadow 300ms ease;
	transition: box-shadow 300ms ease;
	font-family: 'Nunito', sans-serif;
	text-decoration: none;
	color: #ffffff;
	font-size: 17px;
	line-height: 1;
	font-weight: 500;
	box-shadow: 0px 0px 33.6672px #4c1bd3, 0px 0px 19.2384px #4c1bd3, 0px 0px 11.2224px #4c1bd3, 0px 0px 5.6112px #4c1bd3, 0px 0px 1.6032px #4c1bd3, 0px 0px 0.8016px #4c1bd3;
} */

.filled-button:hover {
	border: 1px solid rgba(255, 255, 255, 0.8);
    transform: translateY(-3px);
	box-shadow: 0px 0px 33.6672px #2E069D, 0px 0px 19.2384px #2E069D, 0px 0px 11.2224px #2E069D, 0px 0px 5.6112px #2E069D, 0px 0px 1.6032px #2E069D, 0px 0px 0.8016px #2E069D;
}

/* .filled-button:hover {
	box-shadow: 0px 0px 33.6672px #2E069D, 0px 0px 19.2384px #2E069D, 0px 0px 11.2224px #2E069D, 0px 0px 5.6112px #2E069D, 0px 0px 1.6032px #2E069D, 0px 0px 0.8016px #2E069D;
} */

.filled-button.is-filled-hero-button {
	margin-right: 22px;
}

.hero-buttons-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.outlined-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 170px;
	height: 50px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100px;
	background-color: transparent;
	box-shadow: 0 0 0 1px #00e0b8;
	-webkit-transition: box-shadow 300ms ease;
	transition: box-shadow 300ms ease;
	font-family: 'Nunito', sans-serif;
	color: #00e0b8;
	font-size: 17px;
	line-height: 1;
	font-weight: 500;
}

.outlined-button:hover {
	border-width: 3px;
	box-shadow: 0 0 0 3px #00e0b8;
}

.nav {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 997;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
}

.nav-container {
	height: 100%;
	min-width: 90vw;
	margin-right: 0px;
	margin-left: 0px;
}

.nav-menu-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: -10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 700;
}

.nav-logo-link {
	z-index: 989;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-logo {
	height: 46px;
}

.nav-link-name {
	margin-left: 20px;
	font-size: 17px;
}

.nav-link-block {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 40px;
	padding: 12px 10px 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	line-height: 1;
	text-decoration: none;
}

.nav-link {
	position: absolute;
	width: 14px;
	margin-bottom: 4px;
	opacity: 0;
}

.page-wrap {
	overflow: hidden;
	width: 100%;
}

.s1 {
	position: relative;
	z-index: 900;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 20px;
	padding-bottom: 100px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s1-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -16px;
	margin-left: -16px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.s1-block-container {
	width: 33.333333333333336%;
	padding: 16px;
}

.s1-gradient-outline {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 910;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	box-shadow: inset 0 0 0 2px #2c2c3d;
}

.s1-block {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s1-gradient-mask {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 920;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(7, 7, 28, 0)),
		to(#07071c)
	);
	background-image: linear-gradient(180deg, rgba(7, 7, 28, 0), #07071c);
}

.s1-gradient-glow {
	position: absolute;
	z-index: 930;
	width: 90%;
	height: 2px;
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(rgba(118, 110, 245, 0)),
		color-stop(35%, #766ef5),
		color-stop(65%, #2da9f5),
		to(rgba(45, 169, 245, 0))
	);
	background-image: linear-gradient(
		90deg,
		rgba(118, 110, 245, 0),
		#766ef5 35%,
		#2da9f5 65%,
		rgba(45, 169, 245, 0)
	);
	opacity: 0.95;
}

.s1-gradient-glow.is-green-gradient-glow {
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(rgba(0, 224, 184, 0)),
		color-stop(50%, #00e0b8),
		to(rgba(0, 224, 184, 0))
	);
	background-image: linear-gradient(
		90deg,
		rgba(0, 224, 184, 0),
		#00e0b8 50%,
		rgba(0, 224, 184, 0)
	);
}

.s1-gradient-glow.is-blue-gradient-glow {
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(rgba(45, 169, 245, 0)),
		color-stop(50%, #2da9f5),
		to(rgba(45, 169, 245, 0))
	);
	background-image: linear-gradient(
		90deg,
		rgba(45, 169, 245, 0),
		#2da9f5 50%,
		rgba(45, 169, 245, 0)
	);
}

.s1-content-container {
	position: relative;
	z-index: 940;
	width: 100%;
	padding: 30px 30px 40px;
	text-align: center;
}

.h3 {
	margin-top: 0px;
	margin-bottom: 20px;
	font-family: 'Nunito', sans-serif;
	color: #766ef5;
	font-size: 19px;
	line-height: 1;
	font-weight: 500;
}

.h3.is-green-h3 {
	color: #00e0b8;
}

.h3.is-blue-h3 {
	color: #2da9f5;
}

.s1-numbers {
	font-family: "Spline Sans Mono", monospace;
	font-size: 36px;
	line-height: 1;
	font-weight: 300;
}

.s1-wrapper {
	width: 90%;
}

/* Scroll Text */
.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 74px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
  margin-right: 30px;
}

.animatescrolltext {
	color: rgba(255, 255, 255, 0.95);
	font-style: oblique;
	/* transform:skew(-25deg); */
}

/* Section 2 */

.s2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 25px;
	padding-bottom: 25px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s2::after {
	position: absolute;
	content: '';
	height: 15%;
	width: 100%;
	background-image: url(./images/s3/10.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 120%;
	opacity: 0.4;
	z-index: -1;
}

.s2-info-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 100%;
	margin-bottom: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.h2 {
	margin-top: 0px;
	margin-bottom: 20px;
	font-family: 'Nunito', sans-serif;
	color: #fff;
	font-size: 60px;
	line-height: 1.1;
	font-weight: 500;
}

.h2.is-small-h2 {
	font-size: 52px;
}

.h2.is-small-h2-s6 {
	font-size: 42px;
}

.h2.is-small-h2 {
	font-size: 52px;
}

.h2.is-small-h2.is-s4-h2 {
	width: 90%;
}

.h2.is-vc-h2 {
	margin-bottom: 100px;
	font-size: 32px;
}

.s2-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s2-blocks-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -16px;
	margin-left: -16px;
	padding-top: 100px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.s2-block {
	width: 33.333333333333336%;
	padding: 0px 16px 80px;
}

.s2-content-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 10px;
	background-color: #06061e;
}

.s2-icon-container {
	position: relative;
	z-index: 989;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 60px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s2-h3 {
	position: relative;
	z-index: 989;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 210px;
	height: 110px;
	max-width: 85%;
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Nunito', sans-serif;
	color: #fff;
	font-size: 17px;
	line-height: 1.5;
	font-weight: 500;
	text-align: center;
}

.s2-blur-container {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 900;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 10px;
}

.s2-blur {
	width: 120px;
	height: 100px;
	margin-top: -20px;
	border-radius: 50%;
	background-image: linear-gradient(45deg, #ff862e, #ff3d33);
	-webkit-filter: blur(50px);
	filter: blur(50px);
}

.s2-blur.is-blur-2 {
	background-image: linear-gradient(45deg, #7b43dc, #00d8dc);
}

.s2-blur.is-blur-3 {
	background-image: linear-gradient(45deg, #893af3, #00e0b8);
}

.s2-blur.is-blur-4 {
	background-image: linear-gradient(45deg, #2da9f5, #ae34da);
}

.s2-blur.is-blur-5 {
	background-image: linear-gradient(45deg, #ff3444, #fc6820);
}

.s2-blur.is-blur-6 {
	background-image: linear-gradient(45deg, #9600ff, #ff3d33);
}

.s2-icon {
	width: 155px;
}

.s2-icon.is-icon-3 {
	width: 155px;
}

.s2-icon.is-icon-2 {
	width: 155px;
}

.s2-icon.is-icon-5 {
	width: 155px;
}

.s2-icon.is-icon-6 {
	width: 155px;
}

.s2-icon.is-icon-4 {
	width: 155px;
}

.s2-blocks-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s6 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 75px;
	padding-bottom: 75px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s6-fon {
	background-image: url(./images/s6/01.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 160%;
}

.s6-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
}

.s6-info-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-right: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.section-page--roadmap .slick-arrow {
	top: -110px;
	position: absolute;
}

.section-page--roadmap .slick-arrow.btn-prev {
	right: 80px;
	background-color: #0083ff;
}

.section-page--roadmap .slick-arrow.btn-prev.slick-disabled {
	background-color: #1b1d25;
}

.section-page--roadmap .slick-arrow.btn-next {
	background-color: #0083ff;
	right: 0;
}

.section-page--roadmap .slick-arrow.btn-next.slick-disabled {
	background-color: #1b1d25;
}

.header-roadmap {
	padding: 30px 70px;
	text-align: center;
}
.bg-red {
	background-color: #ef466f;
}
.bg-green {
	background-color: #45b26b;
}
.bg-blue {
	background-color: #3772ff;
}
.box-form__filter .box-filter .text-link,
.box-form__filter .box-filter .boxed-roadmap .box-roadmap .label-roadmap,
.boxed-roadmap .box-roadmap .box-form__filter .box-filter .label-roadmap {
	margin-bottom: 0.83333rem;
	opacity: 0.6;
	display: block;
}

.header-roadmap h2 {
	width: 100%;
	max-width: calc(100% / (12 / 3));
}

.header-roadmap p {
	width: 100%;
	max-width: calc(100% / (12 / 7));
}

.header-roadmap .box-btn {
	width: 100%;
	max-width: calc(100% / (12 / 2));
	text-align: right;
}

.line-roadmap {
	margin-bottom: 1.66667rem;
	position: relative;
	height: 6px;
	transform: translateY(53px);
	width: 100%;
}

.line-roadmap::before {
	content: '';
	height: 6px;
	width: 100%;
	left: 0;
	top: 50%;
	margin-top: -3px;
	position: absolute;
	background-color: #1b1d25;
}

.line-roadmap::after {
	content: '';
	height: 6px;
	width: 50%;
	left: 0;
	top: 50%;
	display: none;
	margin-top: -3px;
	position: absolute;
	background-color: #0083ff;
}

.container--roadmap {
	position: relative;
}

.container--roadmap::before {
	content: '';
	height: 6px;
	width: 100%;
	left: calc(-100% - 150px);
	top: 27px;
	position: absolute;
	background-color: #0083ff;
}

.timeline {
	display: block;
	width: 100%;
	height: 6px;
	background-color: #1b1d25;
	background-image: linear-gradient(to right, #0083ff, #0083ff);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transform: translateY(35px);
	position: relative;
	display: none;
}

.timeline::after {
	content: '';
	height: 100%;
	min-width: calc(1200px - (350px / 2));
	left: 0;
	top: 50%;
	margin-top: -3px;
	position: absolute;
	background-color: #0083ff;
	display: none;
}

.boxed-roadmap {
	position: relative;
}

.boxed-roadmap .slick-list {
	overflow: visible;
}

.boxed-roadmap .list {
	width: 100%;
	max-width: 350px;
	padding: 1.25rem;
	position: relative;
}

.boxed-roadmap .list::after,
.boxed-roadmap .list::before {
	content: '';
	height: 6px;
	width: 50%;
	top: 30px;
	margin-top: -3px;
	position: absolute;
	background-color: #1b1d25;
	transition: all 0.3s ease;
}

.boxed-roadmap .list::before {
	left: 0;
}

.boxed-roadmap .list::after {
	left: -50%;
}

.boxed-roadmap .list .dot {
	width: 30px;
	height: 30px;
	border: 5px solid #fff;
	border-radius: 100px;
	background-color: #0083ff;
	position: relative;
	z-index: 2;
	margin: 0 auto;
	transform: scale(0);
	transition: all 0.3s ease;
}

.boxed-roadmap .list--done .dot {
	transform: scale(1);
}

.boxed-roadmap .list--done::after {
	width: 100%;
	background-color: #0083ff;
}

.boxed-roadmap .box-roadmap {
	text-align: center;
	display: inline-block;
	background-color: #1b1d25;
	border-radius: 20px;
	padding: 2.5rem;
	height: 27rem;
	margin-top: 20px;
}

.boxed-roadmap .box-roadmap .label-roadmap {
	border-radius: 100px;
	display: inline-block;
	height: 40px;
	line-height: 40px;
	padding: 0 1.66667rem;
}

.boxed-roadmap .box-roadmap .label-roadmap,
.boxed-roadmap .box-roadmap h4 {
	margin-bottom: 1.66667rem;
}

.bg-light-gray {
	background-color: #2c303b;
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
	.section-page.section-page--roadmap {
		overflow: hidden;
	}
	.section-page.section-page--roadmap .header-roadmap {
		margin-bottom: 2.5rem;
	}
	.section-page.section-page--roadmap .header-roadmap h2 {
		width: auto;
		max-width: auto;
		margin-right: 3.33333rem;
	}
	.section-page.section-page--roadmap .header-roadmap p {
		width: 100%;
		max-width: auto;
		flex-grow: 1;
		padding-right: 6.66667rem;
	}
	.section-page.section-page--roadmap .slick-arrow {
		top: -130px;
		position: absolute;
	}
	.section-page.section-page--roadmap .slick-arrow.btn-prev {
		left: calc(100% - 130px);
	}
	.section-page.section-page--roadmap .slick-arrow.btn-next {
		left: calc(100% - 50px);
	}
	.section-page.section-page--roadmap .box-roadmap {
		height: auto;
		min-height: 33.33333rem;
	}
}

@media screen and (max-width: 480px) {
	.section-page.section-page--roadmap {
		overflow: hidden;
	}
	.section-page.section-page--roadmap .header-roadmap {
		margin-bottom: 2.5rem;
		flex-direction: column;
		text-align: center;
	}
	.section-page.section-page--roadmap .header-roadmap h2 {
		width: 100%;
		max-width: 100%;
		margin-bottom: 1.66667rem;
	}
	.section-page.section-page--roadmap .header-roadmap p {
		width: 100%;
		max-width: 100%;
	}
	.section-page.section-page--roadmap .container--roadmap {
		padding: 0;
	}
	.section-page.section-page--roadmap .slick-arrow {
		display: none;
	}
	.section-page.section-page--roadmap .boxed-roadmap {
		position: relative;
		padding-bottom: 4.66667rem;
	}
	.section-page.section-page--roadmap .boxed-roadmap .slick-list {
		overflow: hidden;
	}
	.section-page.section-page--roadmap .boxed-roadmap .list {
		width: 100%;
		max-width: 100%;
		padding: 1.25rem;
	}
	.section-page.section-page--roadmap .box-roadmap {
		height: auto/12rem;
	}
	.section-page.section-page--roadmap .slick-arrow {
		top: unset;
		bottom: 0px;
		position: absolute;
	}
	.section-page.section-page--roadmap .slick-arrow.btn-prev {
		left: 16px;
	}
	.section-page.section-page--roadmap .slick-arrow.btn-next {
		left: unset;
		right: 16px;
	}
	.content {
		top: 0;
	}
	.pt6 {
		padding-top: 0;
	}
}
.box-form__filter .box-filter .text-link,
.box-form__filter .box-filter .boxed-roadmap .box-roadmap .label-roadmap,
.boxed-roadmap .box-roadmap .box-form__filter .box-filter .label-roadmap {
	margin-bottom: 0.83333rem;
	opacity: 0.6;
	display: block;
}
@media screen and (max-width: 480px) {
	.boxed-roadmap .box-roadmap .label-roadmap,
	.boxed-roadmap .box-roadmap .box-form__filter .box-filter .label-roadmap {
		margin-bottom: 0.83333rem;
		opacity: 0.6;
		display: block;
	}
	.box-form__filter .box-filter__address {
		margin: 1.33333rem 0;
	}
}
.slick-arrow.btn-next,
.btn-circle.btn-next {
	background-image: url(./images/arrow-right.svg);
}
.s3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 75px;
	padding-bottom: 25px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s3-image-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.s3-info-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-left: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.s3-list-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -12px;
	margin-left: -12px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.s3-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding: 12px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.s3-icon {
	height: 48px;
	margin-right: 12px;
}

.s3-item-description {
	font-size: 16px;
	line-height: 1.4;
}

.div-block-21-copy-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.s5-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 6px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.s5-list-icon {
	width: 32px;
	margin-right: 20px;
}

.s5-item-description {
	margin-top: 6px;
	font-size: 16px;
	line-height: 1.4;
}

.s7 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 100px;
	padding-bottom: 100px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.gray-logo {
	position: absolute;
	z-index: 900;
	width: 100%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.s7-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.logos-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.logo-block {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.333333333333336%;
	padding-top: 50px;
	padding-bottom: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.logo-link {
	position: relative;
	width: 65%;
}

.green-logo {
	position: relative;
	z-index: 989;
	width: 100%;
}

.footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
	padding-top: 15px;
    padding-bottom: 15px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid hsla(0, 0%, 100%, 0.2);
}

.footer-logo {
	height: 98px;
}

.footer-legal {
	width: 200px;
	font-family: 'Nunito', sans-serif;
	color: #686873;
	font-size: 14px;
}

.privacy-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #686873;
	text-decoration: none;
}

.privacy-link:hover {
	color: #00e0b8;
}

.social-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 240px;
	height: 48px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.social-holder {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 16px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.gray-social {
	width: 50px;
}

.green-social {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	width: 50px;
	opacity: 0;
	-webkit-transition: opacity 300ms ease;
	transition: opacity 300ms ease;
}

.green-social:hover {
	opacity: 1;
}

.waves-wrapper {
	position: absolute;
	top: 0px;
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.purple-wave {
	position: absolute;
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	z-index: 30;
	width: 1900px;
	max-width: none;
}

.s3-image-container {
	position: relative;
}

.s3-base-image {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
    max-width: 598px;
	z-index: 100;
}

img.s3-base-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.s3-orbitals-image {
	position: relative;
    max-width: 598px;
	z-index: 1;
}

.s3-box-image {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
    max-width: 598px;
	z-index: 200;
}

img.s3-orbitals-image {
    display: block;
    max-width: 100%;
    height: auto;
}

img.s3-box-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.boxiddd {
    background-color: #00d8dc;
    width: 200px;
    height: 200px;
}

.blue-wave {
	position: absolute;
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	z-index: 20;
	width: 1900px;
	max-width: none;
}

.teal-wave {
	position: absolute;
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	z-index: 10;
	width: 1900px;
	max-width: none;
}

.waves-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	min-width: 100vw;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.s3-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
}

.hero-card {
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 15px 18px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 12px;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(0, 0, 0, 0.75)),
		to(rgba(0, 0, 0, 0.45))
	);
	background-image: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.75),
		rgba(0, 0, 0, 0.45)
	);
	-webkit-transition: box-shadow 300ms ease;
	transition: box-shadow 300ms ease;
	font-family: 'Nunito', sans-serif;
	line-height: 1;
	text-decoration: none;
}

.hero-card:hover {
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.33);
}

.hero-card-wrapper {
	position: absolute;
	left: 71.9vw;
	bottom: 300px;
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.hero-card-wrapper.is-bitcoin {
	left: 52.5vw;
	bottom: 505px;
}

.hero-card-wrapper.is-chainlink {
	left: 57.5vw;
	bottom: 74px;
}

.hero-card-icon {
	width: 40px;
	height: 40px;
	max-width: none;
	margin-right: 12px;
	margin-left: -38px;
	border-radius: 100%;
}

.hero-card-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.hero-card-name {
	margin-bottom: 6px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	color: #fff;
	font-weight: 500;
	white-space: nowrap;
}

.hero-card-short {
	color: #686873;
}

.hero-card-price {
	color: #90b0ff;
	font-size: 25px;
	line-height: 1;
}

.hero-card-stem {
	width: 13px;
}

.s4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 25px;
	padding-bottom: 75px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s4-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
}

.s4-info-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-right: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.s4-image-container {
	position: relative;
}

.s6-cat-image {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
    max-width: 598px;
	z-index: 200;
}

img.s6-cat-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.s4-base-image {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
    max-width: 598px;
	z-index: 100;
}

img.s4-base-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.s4-planets-image {
	position: relative;
    max-width: 598px;
	z-index: 1;
}

img.s4-planets-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.s4-image-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-left: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.s5-image-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.s5-image-container {
	position: relative;
}

.s5 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 75px;
	padding-bottom: 75px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.s5-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90%;
}

.s5-info-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-left: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.s5-top-image {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
    max-width: 598px;
	z-index: 200;
}

img.s5-top-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.s5-coins-image {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
    max-width: 598px;
	z-index: 100;
}

img.s5-coins-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.s5-bottom-image {
	position: relative;
    max-width: 598px;
	z-index: 1;
}

img.s5-bottom-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.s6-image-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-left: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.s6-image-container {
	position: relative;
}

.s6-message-image {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
    max-width: 598px;
	z-index: 200;
}

img.s6-message-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.s6-card-image {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
    max-width: 598px;
	z-index: 100;
}

img.s6-card-image {
	display: block;
    max-width: 100%;
    height: auto;
}

.s6-rocket-image {
	position: relative;
    max-width: 598px;
	z-index: 1;
}

img.s6-rocket-image {
	display: block;
    max-width: 100%;
    height: auto;
}

/* Text position */
.right.vertical-timeline-element-content.bounce-in {
    text-align: right;
	-webkit-box-shadow: 0 3px 0 0, inset 0 2px 0 rgb(0 0 0 / 8%), 0px 0px 20px 4px #4031f7;
}

.left.vertical-timeline-element-content.bounce-in {
	-webkit-box-shadow: 0 3px 0 0, inset 0 2px 0 rgb(0 0 0 / 8%), 0px 0px 20px 4px #4031f7;
}

/* Date position */
span.left.vertical-timeline-element-date {
	text-align: left;
}

span.img_logo.complete.vertical-timeline-element-icon.bounce-in {
	background-color: #2196f3;
	-webkit-box-shadow: 0 0 0 0px #fff, inset 0 2px 0 rgb(0 0 0 / 8%), 0px 0px 10px 0px #7c9fe4;
}

span.img_logo.activity.vertical-timeline-element-icon.bounce-in {
	background-color: white;
	-webkit-box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgb(0 0 0 / 8%),0px 0px 20px 5px #3b77f2;
}

span.img_logo.activity.vertical-timeline-element-icon.bounce-in::before {
	content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #01a4e9;
    animation: pulse-ring 2.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

span.img_logo.activity.vertical-timeline-element-icon.bounce-in::after {
	content: '';
    position: absolute;
    left: 0; 
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #2196f3;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulse-dot 2.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}

@keyframes pulse-ring {
	0% {
	  transform: scale(.23);
	}
	80%, 100% {
	  opacity: 0;
	}
  }
  
@keyframes pulse-dot {
	0% {
	  transform: scale(.6);
	}
	50% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(.6);
	}
}

span.img_logo.process.vertical-timeline-element-icon.bounce-in {
	background-color: #04040e;
	-webkit-box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgb(0 0 0 / 8%), 0px 0px 20px 4px #4031f7;
}
span.img_logo.vertical-timeline-element-icon.bounce-in {
	margin-left: -15px;
    width: 40px;
    height: 40px;
}

@media only screen and (max-width: 1172px) {
	span.img_logo.vertical-timeline-element-icon.bounce-in {
		width: 40px;
		height: 40px;
	}

	.right.vertical-timeline-element-content.bounce-in {
    	text-align: left;
	}
	.s6-fon {
		background-size: 300%;
	}
	.s2::after {
		background-size: 250%;
	}

}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-social-container {
	display: none;
	height: 48px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.nav-social-info {
	display: none;
	font-family: 'Nunito', sans-serif;
}

.hero-blurs {
	position: absolute;
	left: 250px;
	top: auto;
	right: auto;
	bottom: -290px;
	z-index: 5;
	width: 1250px;
}

._404-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

._404-container {
	position: relative;
	z-index: 989;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 500px;
	max-width: 90%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Nunito', sans-serif;
	text-align: center;
}

._404-image {
	position: absolute;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 100;
	width: 100vw;
}

._404-logo {
	width: 150px;
}

._404-logo-link {
	position: absolute;
	top: 50px;
	z-index: 900;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

@media screen and (min-width: 1280px) {
	.hero-card-wrapper {
		left: 953px;
		bottom: 325px;
	}
	.hero-card-wrapper.is-bitcoin {
		left: 733px;
	}
	.hero-card-wrapper.is-chainlink {
		left: 831px;
		bottom: 66px;
	}
}

@media screen and (min-width: 1440px) {
	.hero-info-container {
		max-width: 1296px;
	}
	.nav-container {
		width: 90%;
		max-width: 1296px;
		min-width: 0px;
	}
	.s1-wrapper {
		max-width: 1296px;
	}
	.s2-wrapper {
		max-width: 1296px;
	}
	.s6-container {
		max-width: 1296px;
	}
	.s7-container {
		max-width: 1296px;
	}
	.footer-container {
		max-width: 1296px;
	}
	.waves-wrapper {
		max-width: 1296px;
	}
	.s3-container {
		max-width: 1296px;
	}
	.hero-card-wrapper {
		left: 1100px;
		bottom: 363px;
	}
	.hero-card-wrapper.is-chainlink {
		left: 885px;
		bottom: 81px;
	}
	.s4-container {
		max-width: 1296px;
	}
	.s5-container {
		max-width: 1296px;
	}
	.hero-blurs {
		left: 382px;
	}
}

@media screen and (min-width: 1920px) {
	.hero-card-wrapper {
		left: 1191px;
		bottom: 376px;
	}
	.hero-card-wrapper.is-chainlink {
		left: 938px;
		bottom: 98px;
	}
	._404-image {
		max-width: none;
	}
}

@media screen and (max-width: 991px) {
	.hero-wrapper {
		padding-top: 220px;
		padding-bottom: 150px;
	}
	.h1 {
		margin-bottom: 25px;
	}
	.hero-info {
		z-index: 996;
	}
	.p.is-s2-p {
		width: auto;
	}
	.p.is-s4-p {
		width: auto;
	}

	.filled-button {
		-webkit-transition-property: none;
		transition-property: none;
	}
	.filled-button:hover {
		box-shadow: none;
	}
	.outlined-button {
		-webkit-transition-property: none;
		transition-property: none;
	}
	.outlined-button:hover {
		box-shadow: 0 0 0 1px #00e0b8;
	}
	.nav-menu-container {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		z-index: 900;
		overflow: auto;
		width: 100vw;
		height: 100vh;
		max-height: 100vh;
		margin-right: 0px;
		background-color: #07071c;
	}
	.nav-link-name {
		margin-left: 0px;
		font-size: 60px;
	}
	.nav-link-block {
		z-index: 997;
		width: 100%;
		margin-left: -50px;
		padding: 20px 50px;
	}
	.nav-link {
		display: none;
	}
	.s1 {
		padding-bottom: 75px;
	}
	.s1-container {
		margin-right: -16px;
		margin-left: -16px;
	}
	.s1-block-container {
		width: 50%;
		padding: 0px 16px;
	}
	.s1-block-container.is-total-trades-block-container {
		display: none;
	}
	.s2-info-container {
		margin-bottom: 30px;
	}
	.h2 {
		font-size: 52px;
	}
	.h2.is-small-h2.is-s4-h2 {
		width: 100%;
	}
	.h2.is-vc-h2 {
		margin-bottom: 50px;
	}
	.s2-blocks-container {
		padding-top: 75px;
	}
	.s2-block {
		width: 50%;
		padding-bottom: 60px;
	}
	.s6-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.s6-info-container {
		width: 100%;
		margin-bottom: 50px;
		padding-right: 0px;
	}
	.s3-image-wrapper {
		width: 80%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0px;
	}
	.s3-info-container {
		width: 100%;
		margin-bottom: 50px;
		padding-left: 0px;
	}
	.s3-list-container {
		margin-right: -18px;
		margin-left: -18px;
	}
	.s3-list-item {
		padding: 18px;
	}
	.s3-icon {
		height: 44px;
	}
	.s3-item-description {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
	.green-logo {
		display: inline-block;
		opacity: 0;
	}
	.green-logo:hover {
		opacity: 1;
	}
	.social-holder.is-nav-social-holder {
		margin-left: 0px;
	}
	.green-social:hover {
		opacity: 0;
	}
	.green-social.is-nav-green-social {
		position: static;
		opacity: 1;
	}
	.waves-wrapper {
		width: 100vw;
	}
	.blue-wave {
		margin-left: -25px;
	}
	.menu-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 56px;
		height: 56px;
		margin-top: 28px;
		margin-right: -16px;
		padding: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.menu-button.w--open {
		background-color: transparent;
	}
	.s3-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}
	.hero-card-wrapper {
		display: none;
	}
	.s4-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.s4-info-container {
		width: 100%;
		margin-bottom: 50px;
		padding-right: 0px;
	}
	.s4-image-wrapper {
		width: 80%;
		margin-right: auto;
		margin-left: auto;
		padding-left: 0px;
	}
	.s5-image-wrapper {
		width: 80%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0px;
	}
	.s5-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}
	.s5-info-container {
		width: 100%;
		margin-bottom: 44px;
		padding-left: 0px;
	}
	.s6-image-wrapper {
		width: 80%;
		margin-right: auto;
		margin-left: auto;
		padding-left: 0px;
	}
	.menu-icon-container {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
		z-index: 989;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.menu-icon-bottom {
		width: 24px;
		height: 2px;
		padding-right: 0px;
		padding-bottom: 0px;
		background-color: #fff;
	}
	.menu-icon-top {
		width: 24px;
		height: 2px;
		margin-bottom: 6px;
		padding-right: 0px;
		padding-bottom: 0px;
		background-color: #fff;
	}
	.nav-social-container {
		position: relative;
		z-index: 997;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: -3px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.nav-social-info {
		position: relative;
		z-index: 997;
		display: block;
		margin-top: 50px;
		margin-bottom: 20px;
		font-weight: 500;
	}
	.hero-blurs {
		left: 140px;
		bottom: -200px;
		width: 1000px;
		max-width: none;
	}
}

@media screen and (max-width: 767px) {
	.h1 {
		margin-bottom: 15px;
		font-size: 50px;
	}
	.hero-info {
		max-width: 100%;
	}
	.p {
		margin-bottom: 20px;
	}
	.nav-logo-link {
		padding-left: 0px;
	}
	.nav-logo-link.w--current {
		padding-left: 0px;
	}
	.nav-link-name {
		font-size: 36px;
	}
	.s1 {
		padding-bottom: 50px;
	}
	.s1-container {
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
	}
	.s1-block-container {
		width: 100%;
		margin-bottom: 30px;
		padding-right: 0px;
		padding-left: 0px;
	}
	.h2 {
		margin-bottom: 15px;
		font-size: 40px;
	}
	.h2.is-small-h2 {
		font-size: 40px;
	}
	.s2-blocks-container {
		margin-right: -10px;
		margin-left: -10px;
	}
	.s2-block {
		padding-right: 10px;
		padding-left: 10px;
	}
	.s6 {
		padding-bottom: 50px;
	}
	.s3 {
		padding-bottom: 50px;
	}
	.footer-container {
		position: relative;
		margin-bottom: 30px;
		padding-top: 50px;
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}
	.footer-logo {
		position: absolute;
		left: 0%;
		top: auto;
		right: auto;
		bottom: 0%;
		height: 65px;
	}
	.footer-legal {
		font-size: 13px;
		text-align: center;
	}
	.social-container {
		position: absolute;
		left: auto;
		top: auto;
		right: 0%;
		bottom: 0%;
		width: auto;
		height: auto;
		max-width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.social-holder {
		margin-left: 4px;
	}
	.s6-fon {
		background-size: 640%;
	}
	.s2::after {
		background-size: 540%;
	}
	.gray-social {
		width: 40px;
	}
	.green-social {
		width: 40px;
	}
	.s4 {
		padding-bottom: 50px;
	}
	.s5 {
		padding-bottom: 50px;
	}
	.hero-blurs {
		left: 35px;
	}
}

@media screen and (max-width: 600px) {
	.elementor-section {
		background-size: 700%;
	}
	.hero-wrapper {
		padding-top: 175px;
		padding-bottom: 300px;
	}
	.hero-subheader {
		font-size: 13px;
		letter-spacing: 2px;
	}
	.filled-button.is-filled-hero-button {
		width: 200px;
		margin-right: 0px;
		margin-bottom: 20px;
	}
	.hero-buttons-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
	.outlined-button {
		width: 100%;
		box-shadow: inset 0 0 0 2px #00e0b8;
		color: #fff;
	}
	.nav-logo {
		height: 42px;
	}
	.nav-link-name {
		font-size: 40px;
	}
	.nav-link-block {
		margin-left: -5vw;
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 5vw;
	}
	.s1-numbers {
		font-size: 34px;
	}
	.h2.is-vc-h2 {
		margin-bottom: 30px;
		font-size: 26px;
		text-align: center;
	}
	.s2-blocks-container {
		margin-right: 0px;
		margin-left: 0px;
	}
	.s2-block {
		width: 100%;
		padding-right: 0px;
		padding-left: 0px;
	}
	.s2-h3 {
		height: 95px;
	}
	.s3-image-wrapper {
		width: 100%;
	}
	.s3-box-image {
		max-width: 90vw;
	}
	.s3-base-image {
		max-width: 90vw;
	}
	.s3-orbitals-image {
		max-width: 90vw;
	}
	.s6-cat-image {
		max-width: 90vw;
	}
	.s4-base-image {
		max-width: 90vw;
	}
	.s4-planets-image {
		max-width: 90vw;
	}
	.s5-top-image {
		max-width: 90vw;
	}
	.s5-coins-image {
		max-width: 90vw;
	}
	.s5-bottom-image {
		max-width: 90vw;
	}
	.s6-message-image {
		max-width: 90vw;
	}
	.s6-card-image {
		max-width: 90vw;
	}
	.s6-rocket-image {
		max-width: 90vw;
	}
	.s3-list-container {
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.s3-list-item {
		width: 100%;
		padding-right: 0px;
		padding-left: 0px;
	}
	.s3-icon {
		height: 32px;
		margin-right: 18px;
	}
	.s7 {
		margin-bottom: 50px;
		padding-bottom: 25px;
	}
	.logos-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.logo-block {
		width: 50%;
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.logo-link {
		width: 75%;
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.footer-container {
		padding-top: 60px;
	}
	.footer-logo {
		position: relative;
		left: 0%;
		top: 20%;
		right: auto;
		bottom: auto;
		height: 64px;
		margin-bottom: 20px;
	}
	.footer-legal {
		line-height: 1.7;
	}
	.social-container {
		position: static;
		left: auto;
		top: 5px;
		right: 0%;
		bottom: auto;
		margin-bottom: 30px;
		padding-right: 4px;
	}
	.social-holder.is-nav-social-holder {
		margin-right: 6px;
	}
	.green-social.is-nav-green-social {
		width: 46px;
	}
	.purple-wave {
		left: -269px;
		width: 1600px;
	}
	.blue-wave {
		left: -272px;
		width: 1600px;
	}
	.teal-wave {
		left: -407px;
		width: 1500px;
	}
	.s4-image-wrapper {
		width: 100%;
	}
	.s5-image-wrapper {
		width: 100%;
	}
	.s6-image-wrapper {
		width: 100%;
	}
	.nav-social-container {
		height: auto;
	}
	.nav-social-info {
		margin-top: 45px;
		margin-bottom: 20px;
		font-size: 15px;
	}
	.hero-blurs {
		left: -120px;
		bottom: 0px;
		width: 900px;
	}
	._404-wrap {
		padding-bottom: 100px;
	}
	._404-image {
		width: 900px;
		max-width: none;
	}
	._404-logo {
		width: 130px;
	}
	._404-logo-link {
		top: 35px;
	}
}
@media (min-width: 768px) {
	.ms-2 {
		margin-left: 2rem !important;
	}
}
.box-floating {
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	padding: 1.33333rem;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 989;
	color: #fff;
	background-color: #0083ff;
	-webkit-animation: animateCta 11s infinite;
	animation: animateCta 11s infinite;
	transform: translateY(0px);
}
.box-floating .btn-floating {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 15px;
	margin-right: 1.16667rem;
	transition: all 0.3s;
}
.box-floating span {
	font-size: 12px;
	max-width: 80px;
}

.box-floating {
	text-decoration: none;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	line-height: 14px;
}
.box-floating:hover .btn-floating {
	background-color: rgba(0, 0, 0, 0.2);
}
@-webkit-keyframes animateCta {
	0% {
		transform: translateY(0px);
	}
	90% {
		transform: translateY(0px);
	}
	93% {
		transform: translateY(10px);
	}
	95% {
		transform: translateY(-5px);
	}
	98% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}
@keyframes animateCta {
	0% {
		transform: translateY(0px);
	}
	90% {
		transform: translateY(0px);
	}
	93% {
		transform: translateY(10px);
	}
	95% {
		transform: translateY(-5px);
	}
	98% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

@media screen and (max-width: 480px) {
	body::before {
		display: none;
	}

	.box-floating {
		padding: 0.66667rem;
		bottom: 1.33333rem;
		right: 1.33333rem;
	}
	.box-floating .btn-floating {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 15px;
		margin-right: 0;
	}
	.box-floating span {
		display: none;
	}
}

@media screen and (max-width: 410px) {
	.elementor-section {
		background-size: 920%;
	}
}

@media screen and (max-width: 250px) {
	.elementor-section {
		background-size: 1620%;
	}
}
