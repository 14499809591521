header {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 20;
    height: 80px;
    padding: 0.5rem calc((50vw - 700px) / 2);
    /* background: rgb(17, 17, 66); */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .logo {
    margin-top: 4px;
    width: 270px;
}

.nav-menu {
    display: flex;
    gap: 30px;
    align-items: center;
    margin: 0 30px 0 0;
    padding: 0;
    pointer-events: auto;
}

.nav-menu .nav-menu-link {
    margin: 0 40px;
    padding: 10px;
}

.nav-menu-link {
    text-decoration: none;
    color: white;
    padding: 8px 20px;
    border-radius: 14px;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: 1s;
    text-shadow: 0px 0px 33.6672px #2E069D, 0px 0px 19.2384px #2E069D, 0px 0px 11.2224px #2E069D, 0px 0px 5.6112px #2E069D, 0px 0px 1.6032px #2E069D, 0px 0px 0.8016px #2E069D;
}

.link-privacy {
    color: #686873;
    text-decoration: none;
}


.nav-menu-link:hover {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 1218px) {
    .menu__links {
        display: none;
    }
    .nav-menu-link {
        margin: 0 15px;
        display: none;
    }
    .nav-menu-link-btn {
        margin: 0 30px;
        display: block;
    }
    .language-selector {
        display: block;
    }
}

.nav-menu-link-btn {
    /* height: 98%; */
    text-decoration: none;
}

.nav-menu .start-btn {
    margin: 0;
    width: 174px;
}

.start-btn {
    background: #6732FF;
    width: 294px;  /*Надо будет поменять если хочу сделать снова кнопку скачать*/
    border: 0px;
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 12px;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 280px;
    backdrop-filter: blur(20px);
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    box-shadow: 0px 0px 14.8176px #330AA3, 0px 0px 8.4672px #330AA3, 0px 0px 4.9392px #330AA3, 0px 0px 2.4696px #330AA3, 0px 0px 0.7056px #330AA3, 0px 0px 0.3528px #330AA3;
    transition: 1s;
    cursor: pointer;
    pointer-events: auto;

    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 25px;
}

.start-btn:hover {
    border: 1px solid rgba(255, 255, 255, 0.8);
    transform: translateY(-3px);
}

.mobile-menu-icon {
    position: fixed;
    right: 1rem;
    top: 1.25rem;
    width: 2rem;
    cursor: pointer;
    display: none;
    outline: none;
}

.language-selector {
    padding-top: 10px;
    position: relative;
    display: inline-block;
    max-width: 100%;
}
  
  .selected-language {
    color: white;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .language-options {
    max-width: 95vw; /* Добавлено */
    overflow-x: auto; /* Добавлено */
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #330AA3;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s;
    z-index: 1;
  }

  @media (max-width: 400px) {
    .language-options .option {
        flex-direction: column; /* Элементы становятся вертикально */
    }

    .language-options .option img {
        margin-right: 0;
        margin-bottom: 4px; /* Добавлено: отступ снизу для изображения */
    }
}
  
  .language-options .option {
    color: white;
    padding: 8px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .language-options .option:hover {
    background-color: #6732FF;
  }
  
  .language-options .option img {
    width: 20px;
    height: auto;
    margin-right: 8px;
  }
  
  .language-selector:hover .language-options {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  .selected-language img {
    width: 42px;
    height: auto;
    margin-right: 8px;
  }

  .language-options .option img {
    width: 26px;
    height: auto;
    margin-right: 8px;
  }
  

#nav-icon3 {
    width: 25px;
    height: 25px;
    margin-left: -10px;
    margin-top: 7px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}
  
#nav-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #c4c4c9;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
  
#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: 10px;
}
  
#nav-icon3 span:nth-child(4) {
    top: 20px;
}
  
#nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}
  
#nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
  
#nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
  
#nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.menu__button {
	display: none;
}

.menu__header {
    display: none;
}

.menu__overlay.hidden {
    display: none;
}
  

@media (max-width: 1024px) {
    /* BUTTON UP */
    /* .hide-item {
        transform: translateX(calc(-140% - var(--top)));
    } */
    /* BUTTON UP END */

    /* BUTTON DOWN */
    /* .hide-item {
        transform: translateY(calc(-140% - var(--top)));
    } */
    /* BUTTON DOWN END */
    
    /* .nav-menu-link-btn {
        height: 98%;
    } */
    /* .nav-menu .start-btn {
        display: block;
    } */
    .menu__button {
        display: flex;
    }
    .mobile-menu-icon {
        display: block;
    }
    .nav-menu-link {
        text-shadow: none;
    }
    /* BUTTON END */
    .nav-menu-link {
        display: block;
    }
    .menu__links::-webkit-scrollbar {
        height: 8px;
        /* background-color: #aaa; */
    }
}