

:root {
	--dark: #333;
	--light: #fff;
	--neutral: #f6f6f6;
	--neutral-dark: #d1d1d1;
	--color: #5607d7;
	--color-light: #941ed2;
	--color-dark: #2719cd;
	--font-stack: 'Josefin Sans', 'Montserrat', 'sans-serif';
}

@media screen and (max-width: 1024px) {
    header {
        z-index: 999;
    }

    .nav-menu-link {
        display: none;
    }
    
    .menu__contact {
        display: none;
    }

    .language-selector { 
        padding-top: 0;
    }

    .selected-language img {
        width: 34px;
        height: auto;
        margin-right: 8px;
      }
    
    .language-options .option img {
        width: 26px;
        height: auto;
        margin-right: 8px;
    }
}

@media screen and (min-height: 1290px) {
    nav.nav-menu {
        --y: 83%;
    }
}

@media screen and (max-height: 1280px) {
    nav.nav-menu {
        --y: 75%;
    }
}

@media screen and (max-height: 900px) {
    nav.nav-menu {
        --y: 71%;
    }
}

@media screen and (max-height:750px) {
    nav.nav-menu {
        --y: 67%;
    }
}

@media screen and (max-height: 670px) {
    nav.nav-menu {
        --y: 65%;
    }
}

@media screen and (max-height: 655px) {
    nav.nav-menu {
        --y: 60%;
    }
}

@media screen and (max-height: 570px) {
    nav.nav-menu {
        --y: 57%;
    }
}

@media screen and (max-height: 480px) {
    nav.nav-menu {
        --y: 50%;
    }
}