/* LoaderScreen.css */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #01010e;
}

.loader-logo {
    animation: rotate 20s linear infinite, fadeInOut 2s ease-in-out infinite;
    width: 150px; /* You can adjust this value based on the size of your logo */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

/* Scale animation for some SVG elements */
.loader-logo rect, .loader-logo path {
    animation: scaleAnimation 1.5s ease-in-out infinite;
}

@keyframes scaleAnimation {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
}
