.pb1 {
    padding-bottom: 10px;
}

.pb2 {
    padding-bottom: 20px;
}

.pb3 {
    padding-bottom: 30px;
}

.pb4 {
    padding-bottom: 40px;
}

.pb5 {
    padding-bottom: 50px;
}

.pb6 {
    padding-bottom: 60px;
}

.pt1 {
    padding-top: 10px;
}

.pt2 {
    padding-top: 20px;
}

.pt3 {
    padding-top: 30px;
}

.pt4 {
    padding-top: 40px;
}

.pt5 {
    padding-top: 50px;
}

.pt6 {
    padding-top: 255px;
}

.mt1 {
    padding-top: 10px;
}

