@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(133, 133, 133);
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: var(--red);
  transform-origin: 0%;
}

html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

body {
    position: relative;
    /* background-color: #04040E; OLD*/
    background-color: #01010e;
    font-family: 'Nunito', sans-serif;
    color: #d0d0d5;
    font-size: 18px;
    line-height: 1.5;
    scroll-behavior: smooth;
}

/* body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
header {
  padding: 20px 0;
  background: repeating-linear-gradient(45deg, #f0f2ed, #f0f2ed 20px, #f8f4eb 20px, #f8f4eb 40px);
  box-shadow: 0 4px 6px rgba(0,0,0,.2);  
}
.container {
  padding: 0 20px;
  max-width: 960px;
  margin: 0 auto;
}
.logo-box {
  float: left;
  margin-right: 20px;
}
.logo-box a {
  outline: none;
  display: block;
}
.logo-box img {display: block;}
nav {
  overflow: hidden;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
}
nav li {
  display: inline-block;
  margin-left: 25px;
  height: 70px;
  line-height: 70px;
  transition: .5s linear;
}
nav a {
  text-decoration: none;
  display: block;
  position: relative;
  color: #868686;
  text-transform: uppercase;
}
nav a:after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 15px;
  background: #868686;
  transition: width .5s linear;  
}
nav a:hover:after {width: 100%;}

@media screen and (max-width: 660px) {
  header {text-align: center;}
  .logo-box {
    float: none;
    display: inline-block;
    margin: 0 0 16px 0;
  }
  ul {float: none;}
  nav li:first-of-type {margin-left: 0;}
}
@media screen and (max-width: 550px) {
nav {overflow: visible;}
nav li {
  display: block;
  margin: 0;
  height: 40px;
  line-height: 40px;
}
nav li:hover {background: rgba(0,0,0,.1);}
nav a:after {content: none;}
} */