.links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Добавьте следующий код */
  @media (max-width: 700px) {
    .links-container {
      height: auto;
      padding: 20px;
    }
  }
  
  @media (max-width: 500px) {
    .links-container {
      font-size: 14px;
    }
  }
  
  @media (max-width: 320px) {
    .links-container {
      font-size: 12px;
    }
  }
  
  
.social-link {
    text-decoration: none;
    color: #fff;
    width: 100%;
    max-width: 330px;
    margin-bottom: 10px;
}
  
.social-link-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    padding: 12px;
    margin-bottom: 4px;
    background-color: #6732FF;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    transition: 1s;
    backdrop-filter: blur(20px);
    box-shadow: 0px 0px 14.8176px #330AA3, 0px 0px 8.4672px #330AA3, 0px 0px 4.9392px #330AA3, 0px 0px 2.4696px #330AA3, 0px 0px 0.7056px #330AA3, 0px 0px 0.3528px #330AA3;
}

.social-link-block:hover {
    border: 1px solid rgba(255, 255, 255, 0.8);
    transform: translateY(-3px);
}
  
.social-link-block img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

@media (max-width: 767px) {
    .social-link {
        margin-bottom: 5px;
    }

    .social-link-block {
        padding: 12px;
        margin-bottom: 2px;
        box-shadow: 0px 0px 10px #330AA3, 0px 0px 5px #330AA3, 0px 0px 3px #330AA3, 0px 0px 1px #330AA3;
    }

    .social-link-block img {
        width: 24px;
        height: 24px;
        margin-left: 8px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .social-link {
        margin-bottom: 8px;
    }

    .social-link-block {
        padding: 10px;
        margin-bottom: 3px;
        box-shadow: 0px 0px 12px #330AA3, 0px 0px 6px #330AA3, 0px 0px 4px #330AA3, 0px 0px 2px #330AA3;
    }

    .social-link-block img {
        width: 26px;
        height: 26px;
        margin-left: 9px;
    }
}

@media (max-width: 330px) {
    .social-link-block {
        padding: 8px;
    }
}